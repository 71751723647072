import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import { Nav, NavItem, NavLink, Row, Col, Badge } from 'reactstrap';
import classnames from 'classnames';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconRegistries } from '../../images/icn_anagrafiche.svg';
import {
  H4Styled,
  BtnRadiusWhite,
  ContFlexLeft,
  ContFlexRight,
} from '../../styled-components';
import ProfileInfo from './ProfileInfo';
import Affiliations from './Affiliations';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import './ProfileDetail.scss';
import './TabsProfile.scss';
import { get } from '../../lib/fetch/profiles';
import FinancialReports from './FinancialReports';
import { useAlert } from 'react-alert';
import EnergyRecurring from './EnergyRecurring';
import EnergyRecurringAdmin from './EnergyRecurringAdmin';
import Energy from './Energy';
import EnergyAdmin from './EnergyAdmin';
import { useLoading } from '../../commons/Loading';
import User from './User';
import Royalties from './Royalties';
import InvoicesStudio from './InvoicesStudio';
import { useAuth } from '../../commons/Auth';
import Insurances from './Insurances';
import {
  ADMIN_TYPES,
  AGENT_TYPES,
  CERTIFIER_TYPE,
  PROFILE_DOCUMENTS_TYPES,
  ROYALTIES_ALLOWED_TYPES,
  USER_ADMIN_TYPES,
} from '../../App';
import variables from '../../variables';
import { AdminTypes, SupplierTypes } from '../../lib/constants';
import InsurancesAdmin from './InsurancesAdmin';
import ScoreAdmin from './ScoreAdmin';
import NetworkAdmin from './NetworkAdmin';
import NetworkFinancialReports from './NetworkFinancialReports';
import { refactoringAdminLevels } from '../../lib/helpers/refactoringAdminLevels';
import CreditCalculationAdmin from './CreditCalculationAdmin';
import AdminCondos from './Condos';
import CollaboratorsAdmin from './CollaboratorsAdmin';
import ProfileDocuments from './ProfileDocuments';
import axios from 'axios';

function ProfileDetail(props) {
  const [{ token, isAdmin, userType = '' } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [profile, setProfile] = useState({});
  const [pageBack, setPageBack] = useState('');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const { history, location, match = {} } = props;
  const { params: { id } = {}, url = '' } = match;
  const { pathname = '', state = {} } = location;
  const tab = pathname.split('/').pop();
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    if (state.pageBack) setPageBack(state.pageBack);
  }, [state]);

  useEffect(() => {
    if (id !== 'create') {
      fetchProfile(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchManagers = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/managers`,
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    let { data = [], headers = {} } = response;
    setManagers(data);
  };

  const fetchProfile = async (id) => {
    showLoading();

    const { error, data } = await get({ id, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    await fetchManagers();
    setProfile(data);
    hideLoading();
  };

  if (pathname === url) {
    return <Redirect to={`${url}/info`} />;
  }

  const isUpdating = id !== 'create';

  const { activeAffiliation = false, type = '', businessName = '' } = profile;

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:REGISTRIES')}
            icon={<IconRegistries fill={variables.white} />}
          />
        </section>
        <section>
          <Row>
            <Col sm="12" md="6">
              <ContFlexLeft>
                <BtnRadiusWhite onClick={() => history.replace(`/${pageBack}`)}>
                  <IconBack fill={variables.primary} />
                </BtnRadiusWhite>
                <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
              </ContFlexLeft>
            </Col>
            {isUpdating && (
              <Col sm="12" md="6">
                <ContFlexRight>
                  <H4Styled className="registry-name">
                    <span>
                      {businessName} -{' '}
                      <small>{t(refactoringAdminLevels(type))}</small>
                    </span>
                  </H4Styled>
                  <Badge
                    className={`affiliation${
                      activeAffiliation ? ' active' : ' not-active'
                    }`}
                  >
                    {t('AFFILIATION')}
                    <br />
                    {t(`${activeAffiliation ? 'ACTIVE' : 'INACTIVE'}`)}
                  </Badge>
                </ContFlexRight>
              </Col>
            )}
          </Row>

          <div className="m-tb-20">
            <Nav tabs>
              <NavItem>
                <NavLink
                  tag={Link}
                  to={`${match.url}/info`}
                  className={classnames({ active: tab === 'info' })}
                >
                  {t('CUSTOMER_DATA')}
                </NavLink>
              </NavItem>
              {isUpdating && (
                <NavItem>
                  <NavLink
                    tag={Link}
                    to={`${match.url}/documents`}
                    className={classnames({
                      active: tab === 'documents',
                    })}
                  >
                    {t('DOCUMENTS:PROFILE_TITLE')}
                  </NavLink>
                </NavItem>
              )}
              {isUpdating && (
                <>
                  {[...AdminTypes, ...SupplierTypes, 'AGENCY'].includes(
                    profile.type
                  ) &&
                    userType !== USER_ADMIN_TYPES.SUPERVISOR_USER && (
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to={`${match.url}/affiliations`}
                          className={classnames({
                            active: tab === 'affiliations',
                          })}
                        >
                          {t('AFFILIATIONS')}
                        </NavLink>
                      </NavItem>
                    )}
                  {ADMIN_TYPES.includes(profile.type) ||
                  profile.type === CERTIFIER_TYPE.CERTIFIER ? (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/condos`}
                        className={classnames({
                          active: tab === 'condos',
                        })}
                      >
                        {t('CONDOS')}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <></>
                  )}
                  {ROYALTIES_ALLOWED_TYPES.includes(profile.type) && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/financial-reports`}
                        className={classnames({
                          active:
                            tab === 'financial-reports' ||
                            tab === 'variable-revenues' ||
                            tab === 'variable-revenues-insurances' ||
                            tab === 'sub-admins-revenues',
                        })}
                      >
                        {t('PAGES:FINANCIAL_REPORTS')}
                      </NavLink>
                    </NavItem>
                  )}
                  {!(
                    profile.type === 'COMMERCIAL' ||
                    profile.type === 'SUPPLIER_INS' ||
                    profile.type === 'EON_ENERGY' ||
                    profile.type === 'GENERIC_CUSTOMER' ||
                    profile.type === 'CERTIFIER'
                  ) && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/invoices`}
                        className={classnames({
                          active: tab === 'invoices',
                        })}
                      >
                        {t('INVOICES:TAB_TITLE')}
                      </NavLink>
                    </NavItem>
                  )}
                  {(profile.type === 'BUILDING_ADMIN_1' ||
                    profile.type === 'BUILDING_ADMIN_2' ||
                    profile.type === 'BUILDING_ADMIN_3' ||
                    profile.type === 'BUILDING_ADMIN_4' ||
                    profile.type === 'BUILDING_ADMIN_5') &&
                    userType !== USER_ADMIN_TYPES.SUPERVISOR_USER && (
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to={`${match.url}/invoices-studio`}
                          className={classnames({
                            active: tab === 'invoices-studio',
                          })}
                        >
                          {t('INVOICES_STUDIO:TITLE')}
                        </NavLink>
                      </NavItem>
                    )}

                  {profile.type === 'SUPPLIER_INS' && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${
                          match.url
                        }/insurances?year=${new Date().getFullYear()}`}
                        className={classnames({
                          active: tab === 'insurances',
                        })}
                      >
                        {t('INSURANCES:TITLE')}
                      </NavLink>
                    </NavItem>
                  )}
                  {(profile.type === 'AGENCY' ||
                    profile.type === 'BUILDING_ADMIN_1' ||
                    profile.type === 'BUILDING_ADMIN_2' ||
                    profile.type === 'BUILDING_ADMIN_3' ||
                    profile.type === 'BUILDING_ADMIN_4' ||
                    profile.type === 'BUILDING_ADMIN_5') && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${
                          match.url
                        }/insurances-admin?year=${new Date().getFullYear()}`}
                        className={classnames({
                          active: tab === 'insurances-admin',
                        })}
                      >
                        {t('INSURANCES:TITLE')}
                      </NavLink>
                    </NavItem>
                  )}

                  {!(profile.type === 'GENERIC_CUSTOMER') && (
                    <NavItem>
                      <NavLink
                        to={`${match.url}/user`}
                        tag={Link}
                        className={classnames({
                          active: tab === 'user',
                        })}
                      >
                        {t('USERS:TITLE')}
                      </NavLink>
                    </NavItem>
                  )}

                  {profile.type === 'EON_ENERGY' && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/energy`}
                        className={classnames({
                          active: tab === 'energy',
                        })}
                      >
                        {t('ENERGY:TITLE')}
                      </NavLink>
                    </NavItem>
                  )}
                  {(profile.type === 'AGENCY' ||
                    profile.type === 'BUILDING_ADMIN_1' ||
                    profile.type === 'BUILDING_ADMIN_2' ||
                    profile.type === 'BUILDING_ADMIN_3' ||
                    profile.type === 'BUILDING_ADMIN_4' ||
                    profile.type === 'BUILDING_ADMIN_5') && (
                    <>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to={`${match.url}/energy-admin`}
                          className={classnames({
                            active: tab === 'energy-admin',
                          })}
                        >
                          {t('ENERGY:TITLE')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to={`${match.url}/energy-admin/recurring`}
                          className={classnames({
                            active: tab === 'recurring',
                          })}
                        >
                          {t('ENERGY_RECURRENT:TITLE')}
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                  {profile.type === 'EON_ENERGY' && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/energy/recurring`}
                        className={classnames({
                          active: tab === 'energy-recurrent',
                        })}
                      >
                        {t('ENERGY_RECURRENT:TITLE')}
                      </NavLink>
                    </NavItem>
                  )}
                  {ADMIN_TYPES.includes(profile.type) && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/score-admin`}
                        className={classnames({
                          active: tab === 'score-admin',
                        })}
                      >
                        {t('SCORE:TITLE')}
                      </NavLink>
                    </NavItem>
                  )}
                  {ADMIN_TYPES.includes(profile.type) && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/credit-calc-admin`}
                        className={classnames({
                          active: tab === 'credit-calc-admin',
                        })}
                      >
                        {t('CREDIT_CALCULATION:TITLE')}
                      </NavLink>
                    </NavItem>
                  )}
                  {profile?.commercial && (
                    <>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to={`${match.url}/network-admin`}
                          className={classnames({
                            active: tab === 'network-admin',
                          })}
                        >
                          {t('COMMERCIAL:NETWORK')}
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                  {profile?.commercial &&
                    userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to={`${match.url}/network-financial-report-admin`}
                          className={classnames({
                            active: tab === 'network-financial-report-admin',
                          })}
                        >
                          {t('COMMERCIAL:FINANCIAL_REPORT')}
                        </NavLink>
                      </NavItem>
                    )}
                  {ADMIN_TYPES.includes(profile.type) &&
                    userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to={`${match.url}/admin-collaborators`}
                          className={classnames({
                            active: tab === 'admin-collaborators',
                          })}
                        >
                          {t('COLLABORATORS:TITLE')}
                        </NavLink>
                      </NavItem>
                    )}
                </>
              )}
            </Nav>

            <Switch>
              <Route
                path={`${match.url}/info`}
                component={(props) => (
                  <ProfileInfo
                    id={id}
                    profile={profile}
                    setProfile={setProfile}
                    managers={managers}
                    {...props}
                  />
                )}
              />
              <Route
                path={`${match.url}/affiliations`}
                component={(props) => (
                  <Affiliations id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/condos`}
                component={(props) => (
                  <AdminCondos id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/financial-reports`}
                component={(props) => (
                  <FinancialReports profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/invoices`}
                component={(props) => (
                  <Royalties id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/invoices-studio`}
                component={(props) => (
                  <InvoicesStudio id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/insurances`}
                component={(props) => (
                  <Insurances id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/insurances-admin`}
                component={(props) => (
                  <InsurancesAdmin
                    id={id}
                    profile={profile}
                    userType={userType}
                    {...props}
                  />
                )}
              />
              <Route
                path={`${match.url}/user`}
                component={(props) => (
                  <User
                    id={id}
                    profile={profile}
                    setProfile={setProfile}
                    {...props}
                  />
                )}
              />

              <Route
                path={`${match.url}/energy/recurring`}
                component={(props) => (
                  <EnergyRecurring id={id} profile={profile} {...props} />
                )}
              />

              <Route
                path={`${match.url}/energy-admin/recurring`}
                component={(props) => (
                  <EnergyRecurringAdmin
                    id={id}
                    profile={profile}
                    userType={userType}
                    {...props}
                  />
                )}
              />

              <Route
                path={`${match.url}/energy`}
                component={(props) => (
                  <Energy id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/energy-admin`}
                component={(props) => (
                  <EnergyAdmin
                    id={id}
                    profile={profile}
                    userType={userType}
                    {...props}
                  />
                )}
              />
              <Route
                path={`${match.url}/score-admin`}
                component={(props) => (
                  <ScoreAdmin id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/credit-calc-admin`}
                component={(props) => (
                  <CreditCalculationAdmin
                    id={id}
                    profile={profile}
                    {...props}
                  />
                )}
              />
              <Route
                path={`${match.url}/network-admin`}
                component={(props) => (
                  <NetworkAdmin id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/network-financial-report-admin`}
                component={(props) => (
                  <NetworkFinancialReports profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/admin-collaborators`}
                component={(props) => (
                  <CollaboratorsAdmin profile={profile} {...props} />
                )}
              />

              <Route
                path={`${match.url}/documents`}
                component={(props) => (
                  <ProfileDocuments id={id} profile={profile} {...props} />
                )}
              />
            </Switch>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default withRouter(ProfileDetail);
