import React, { useState, useEffect } from 'react';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import ContractsPresentational from './ContractsPresentational';
import contractsAPIs, { importContracts } from '../../lib/fetch/contractsAPIs';
import axios from 'axios';
import { profileScoreFormatter } from '../../lib/helpers/profileScoreFormatter';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const Contracts = ({ profile }) => {
  const [contracts, setContracts] = useState([]);
  const [formattedCSVContracts, setFormattedCSVContracts] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token, isAdmin } = {}] = useAuth();
  const [hasImportedContracts, setHasImportedContracts] = useState(false);
  const [
    contractsImportFeedbackData,
    setContractsImportFeedbackData,
  ] = useState({});
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  const [profileScore, setProfileScore] = useState({});

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    year: NumberParam,
    pod_pdr: withDefault(StringParam, ''),
    condo_admin_cf: withDefault(StringParam, ''),
    condo_vat_cf: withDefault(StringParam, ''),
    address: withDefault(StringParam, ''),
  });

  const {
    year,
    limit,
    offset,
    admin,
    condo_vat_cf,
    condo_admin_cf,
    pod_pdr,
  } = query;

  const toggleShowImportFeedback = () => setHasImportedContracts((v) => !v);

  function onFilter(queryDiff) {
    if (
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.pod_pdr && queryDiff.pod_pdr !== pod_pdr) || // first search
      (pod_pdr && queryDiff.pod_pdr === '') || // search deleted
      (queryDiff.condo_vat_cf && queryDiff.condo_vat_cf !== condo_vat_cf) || // first search
      (condo_vat_cf && queryDiff.condo_vat_cf === '') || // search deleted
      (queryDiff.condo_admin_cf &&
        queryDiff.condo_admin_cf !== condo_admin_cf) || // first search
      (condo_admin_cf && queryDiff.condo_admin_cf === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const fetchContracts = async (query) => {
    const {
      data,
      total: _total,
      formattedCSVContracts,
    } = await contractsAPIs.find({ token, query });
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/scores`,
      {
        headers: { Authorization: token },
      }
    );
    setTotal(Number(_total || 0));
    setContracts(data);
    setProfileScore(
      profileScoreFormatter(response.data, response.data.type || 'auto')
    );
  };

  const fetchContractsForExport = async (query) => {
    const {
      data,
      total: _total,
      formattedCSVContracts,
    } = await contractsAPIs.findForExport({ token, query });
    setFormattedCSVContracts(formattedCSVContracts);
  };

  const onImportContract = async (file) => {
    showLoading();
    const { error, data } = await contractsAPIs.importContracts({
      token,
      file,
    });
    hideLoading();
    toggleShowImportFeedback();
    setContractsImportFeedbackData({
      ...data,
      validationErrors: error?.response?.data,
    });
    fetchContracts({ pod_pdr, condo_vat_cf, condo_admin_cf, offset, limit });
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
    } else {
      alert.success(t('COMMON:SUCCESS_UPLOAD'));
    }
  };

  useEffect(() => {
    fetchContracts({
      year,
      pod_pdr,
      condo_vat_cf,
      condo_admin_cf,
      offset,
      limit,
    });
    fetchContractsForExport({
      year,
      pod_pdr,
      condo_vat_cf,
      condo_admin_cf,
      offset: 0,
      limit: 5000,
    });
  }, [year, condo_vat_cf, pod_pdr, condo_admin_cf, offset, limit]);

  return (
    <ContractsPresentational
      contractsImportFeedbackData={contractsImportFeedbackData}
      data={contracts}
      formattedData={formattedCSVContracts}
      profile={profile}
      profileScore={profileScore}
      query={query}
      total={total}
      onFilter={onFilter}
      onImportContract={onImportContract}
      toggleShowImportFeedback={toggleShowImportFeedback}
      hasImportedContracts={hasImportedContracts}
    />
  );
};

export default Contracts;
