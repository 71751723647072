import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import InvoicesStudioPresentational from './InvoicesStudioPresentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;

const Royalties = ({ profile = {} }) => {
  const [invoices, setInvoices] = useState([]);
  const [retrocession, setRetrocession] = useState(0);
  const [affiliation, setAffiliation] = useState(null);
  const [total, setTotal] = useState(0);
  const [{ isAdmin, token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT || 20),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
  });

  const { limit, offset, year, customer, number } = query;

  useEffect(() => {
    if (profile._id) fetchInvoices();
  }, [limit, offset, year, customer, number]);

  useEffect(() => {
    if (profile._id) fetchAffiliations();
  }, [year]);

  const fetchInvoices = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/studio-invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          year,
          customer,
          number,
        },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { headers, data } = response;

    setInvoices(data.data);
    setRetrocession(data.retrocession);

    setTotal(+headers['x-total-count']);
    hideLoading();
  };

  const fetchAffiliations = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/affiliations/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          year,
        },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data } = response;

    setAffiliation(data[0] ?? null);

    hideLoading();
  };

  function setFilterYear(value) {
    setQuery({ year: value });
  }

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myCompensiVariabiliSearch', queryDiff.search);
    }
    if (
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.number && queryDiff.number !== number) || // first search
      (number && queryDiff.number === '') // search deleted
    ) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const invoiceStudio = async (studioToInvoice, totalToInvoice) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/extra-invoices/${profile._id}/invoice-studio`,
      {
        year,
        studioToInvoice,
        affiliationId: affiliation._id,
        totalToInvoice,
      },
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    fetchInvoices();
  };

  const onStatusChanged = () => {
    fetchInvoices();
  };

  return (
    <InvoicesStudioPresentational
      data={invoices}
      retrocession={retrocession}
      affiliation={affiliation}
      isAdmin={isAdmin}
      query={query}
      total={total}
      onFilter={onFilter}
      setFilterYear={setFilterYear}
      invoiceStudio={invoiceStudio}
      onStatusChanged={onStatusChanged}
    />
  );
};

export default Royalties;
